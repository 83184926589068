import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, throwError } from "rxjs";
import { filter, tap, take } from 'rxjs/operators';

interface AsyncCacheNamespace<T> {
   (key: string): BehaviorSubject<T>;
}

interface AsyncCache<T> {
   (cacheKey: string): AsyncCacheNamespace<T>;
}

@Injectable({
   providedIn: 'root',
})
export class AsyncCacheService<T> {
   private cache = {} as AsyncCache<T>;

   constructor()
   {}

   public initAsyncCache(cacheKey: string) {
      this.cache[cacheKey] ??= {};
      const cacheByNameSpace = this.cache[cacheKey];

      function fetch<K>(key: string, apiToCall: any, forceFetch: boolean=false): Observable<K> {
         if (forceFetch || !(key in cacheByNameSpace))
         {
            if (!(key in cacheByNameSpace)) {
               cacheByNameSpace[key] = new BehaviorSubject<K>(null);
            }
            return apiToCall
               .pipe(
                  filter((result) => result !== null),
                  tap((result) => {
                     cacheByNameSpace[key].next(result);
                     return cacheByNameSpace[key].asObservable();
                  }),
               );
         }
         else {
            return cacheByNameSpace[key].asObservable()
               .pipe(
                  filter((value) => value !== null),
                  take(1),
               );
         }
      }

      function find<W>(key: string): Observable<W> {
         if (!(key in cacheByNameSpace)) {
            const error = new Error(key + 'invalid');
            return throwError(error);
         }
         return cacheByNameSpace[key].asObservable()
            .pipe(
               filter((value) => value !== null),
            );
      }
      return { fetch, find };
   }
}
